import React, { useState } from 'react';
import {
  Box,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Text,
  FormErrorMessage,
  Textarea,
  Button,
  Checkbox,
  useToast,
} from '@chakra-ui/react';
import emailjs from 'emailjs-com';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    company: '',
    presentation: false,
    trial: false,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    company: '',
  });

  const toast = useToast(); 
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = { name: '', email: '', phone: '', message: '', company: '' };

    if (!formData.name) {
      newErrors.name = 'Le nom est requis.';
      valid = false;
    }
    if (!formData.email) {
      newErrors.email = 'L\'adresse e-mail est requise.';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'L\'adresse e-mail est invalide.';
      valid = false;
    }
    if (!formData.phone) {
      newErrors.phone = 'Le numéro de téléphone est requis.';
      valid = false;
    }
    if (!formData.message) {
      newErrors.message = 'Le message est requis.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!validateForm()) {
      toast({
        title: 'Erreur de validation',
        description: 'Veuillez corriger les erreurs dans le formulaire.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    if (!executeRecaptcha) {
      toast({
        title: 'Erreur reCAPTCHA',
        description: 'reCAPTCHA n\'a pas pu être chargé. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const recaptchaToken = await executeRecaptcha('submit');
    if (!recaptchaToken) {
      toast({
        title: 'Erreur reCAPTCHA',
        description: 'reCAPTCHA n\'a pas été validé. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const formDataWithRecaptcha = { ...formData, recaptchaToken };
  
    emailjs.send(
      'service_k7byakm', // Remplacez par votre Service ID
      'template_rbqbe1b', // Remplacez par votre Template ID
      formDataWithRecaptcha,
      '1PENVQU0-AW1d8jzc' // Remplacez par votre User ID (Public Key)
    )
    .then((result) => {
      toast({
        title: 'Message envoyé',
        description: 'Votre message a été envoyé avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: '',
        company: '',
        presentation: false,
        trial: false,
      });
    }, (error) => {
      toast({
        title: 'Erreur',
        description: "Erreur lors de l'envoi de l'e-mail.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    });
  };  

  return (
    <Box bg="blue.500" py={10} px={6}>
      <VStack spacing={4} mb={10} textAlign="center" color="white">
        <Heading fontSize="3xl">Commencez dès maintenant !</Heading>
        <Text fontSize="lg">
          Les experts de ProtectoSystem vous montreront comment nos solutions peuvent être intégrées dans vos logiciels existants.
        </Text>
      </VStack>
      <Box bg="white" p={8} borderRadius="lg" shadow="lg" maxW="lg" mx="auto">
        <VStack spacing={4} as="form" onSubmit={handleSubmit}>
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>Nom</FormLabel>
            <Input name="name" placeholder="Votre nom" value={formData.name} onChange={handleChange} />
            {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
          </FormControl>
          <FormControl>
            <FormLabel>Entreprise</FormLabel>
            <Input name="company" placeholder="Votre entreprise" value={formData.company} onChange={handleChange} />
          </FormControl>
          <FormControl isInvalid={!!errors.phone} isRequired>
            <FormLabel>Téléphone</FormLabel>
            <Input name="phone" placeholder="Votre téléphone" value={formData.phone} onChange={handleChange} />
            {errors.phone && <FormErrorMessage>{errors.phone}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel>Adresse e-mail</FormLabel>
            <Input name="email" type="email" placeholder="Votre adresse e-mail" value={formData.email} onChange={handleChange} />
            {errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.message} isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea name="message" placeholder="Votre message" value={formData.message} onChange={handleChange} />
            {errors.message && <FormErrorMessage>{errors.message}</FormErrorMessage>}
          </FormControl>
          <Checkbox name="presentation" isChecked={formData.presentation} onChange={handleChange}>
            Je souhaite une présentation de ProtectoSystem
          </Checkbox>
          <Checkbox name="trial" isChecked={formData.trial} onChange={handleChange}>
            Je souhaite un essai gratuit de ProtectoSystem
          </Checkbox>
          <Button colorScheme="blue" size="lg" width="full" type="submit">
            Envoyer
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

export default ContactUs;
