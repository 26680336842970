import React from 'react';
import {
  Box,
  Text,
  VStack,
  Wrap,
  WrapItem,
  Tag,
} from '@chakra-ui/react';

const AboutSection: React.FC = () => {
  return (
    <Box
      textAlign="center"
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 6 }}
      bg="gray.100" // Fond gris clair similaire à l'image
      backgroundImage="url('/path-to-your-background-image.png')" // Remplacez avec votre chemin d'image si nécessaire
      backgroundSize="cover"
      backgroundPosition="center"
      borderRadius="md"
    >
      <VStack spacing={{ base: 4, md: 6 }} maxW="4xl" mx="auto">
        <Text
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
        >
          À propos de ProtectoSystem
        </Text>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" lineHeight="tall">
          ProtectoSystem se positionne à l'avant-garde de l'innovation technologique dans le secteur de la sécurité et du secours en France. Dans un contexte où la rapidité et l'efficacité des interventions sont cruciales, notre entreprise apporte une solution logicielle intégrée qui révolutionne la gestion des missions. Grâce à la couverture réseaux de notre pays, notre solution est efficiente.
        </Text>

        <Wrap justify="center" spacing={4} mt={4}>
          <WrapItem>
            <Tag size="lg" colorScheme="blue">
              INNOVANT
            </Tag>
          </WrapItem>
          <WrapItem>
            <Tag size="lg" colorScheme="green">
              COLLABORATIF
            </Tag>
          </WrapItem>
          <WrapItem>
            <Tag size="lg" colorScheme="orange">
              INTUITIF
            </Tag>
          </WrapItem>
          <WrapItem>
            <Tag size="lg" colorScheme="red">
              RÉACTIF
            </Tag>
          </WrapItem>
        </Wrap>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" lineHeight="tall" mt={4}>
          Notre plateforme répond aux défis complexes auxquels font face les organisations de sécurité et de secours aujourd'hui : coordination des équipes, gestion des ressources en temps réel, et communication instantanée pour en citer 3. En centralisant ces aspects critiques sur une interface unique et intuitive, ProtectoSystem vise à optimiser les opérations et à améliorer significativement la réactivité des services d'urgence.
        </Text>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" lineHeight="tall" mt={4}>
          Cette vitrine détaille notre vision, notre stratégie et les moyens que nous mettrons en œuvre pour devenir le partenaire incontournable des acteurs de la sécurité et du secours en France.
        </Text>
      </VStack>
    </Box>
  );
};

export default AboutSection;
