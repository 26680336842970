import React from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  SimpleGrid,
  Tag,
  Flex,
} from '@chakra-ui/react';

const FeaturesSection: React.FC = () => {
  return (
    <Box textAlign="center" py={{ base: 6, md: 10 }} px={{ base: 4, md: 6 }} bg="white">
      <VStack spacing={{ base: 4, md: 6 }}>
        <Text
          fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
        >
          Découvrez nos fonctionnalités
        </Text>

        {/* Feature Cards */}
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8} mt={8} width="100%">
          <Box
            bg="gray.50"
            p={6}
            borderRadius="md"
            boxShadow="lg"
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="2xl" color="blue.500">1</Text>
            <Text fontSize="lg" color="gray.800">Planification des missions</Text>
          </Box>

          <Box
            bg="gray.50"
            p={6}
            borderRadius="md"
            boxShadow="lg"
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="2xl" color="blue.500">2</Text>
            <Text fontSize="lg" color="gray.800">Suivi en temps réel</Text>
          </Box>

          <Box
            bg="gray.50"
            p={6}
            borderRadius="md"
            boxShadow="lg"
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="2xl" color="blue.500">3</Text>
            <Text fontSize="lg" color="gray.800">Coordination des équipes</Text>
          </Box>

          <Box
            bg="gray.50"
            p={6}
            borderRadius="md"
            boxShadow="lg"
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="2xl" color="blue.500">4</Text>
            <Text fontSize="lg" color="gray.800">Rapports d'activité</Text>
          </Box>
        </SimpleGrid>

        {/* Action Buttons */}
        <HStack
          spacing={{ base: 2, md: 4 }}
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          mt={8}
        >
          <Button colorScheme="blue" size="lg" width={{ base: 'full', sm: 'auto' }}>
            En savoir plus
          </Button>
          <Button
            variant="outline"
            colorScheme="blue"
            size="lg"
            width={{ base: 'full', sm: 'auto' }}
          >
            Contactez-nous
          </Button>
        </HStack>

        {/* Tags */}
        <Flex justifyContent="center" mt={4} wrap="wrap">
          <Tag size="lg" colorScheme="purple" m={2}>
            INNOVANT
          </Tag>
          <Tag size="lg" colorScheme="green" m={2}>
            EFFICACE
          </Tag>
          <Tag size="lg" colorScheme="orange" m={2}>
            COLLABORATIF
          </Tag>
        </Flex>
      </VStack>
    </Box>
  );
};

export default FeaturesSection;
