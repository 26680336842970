import React from 'react';
import { Box, SimpleGrid, Text, VStack, Icon } from '@chakra-ui/react';
import { FcShop, FcServices } from 'react-icons/fc';

const ServiceOfferSection: React.FC = () => {
  return (
    <Box 
      py={10} 
      px={{ base: 4, md: 20, lg: 40, xl: 60 }}  
      bg="white"
    >
      <VStack spacing={8} textAlign="center">
        <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
          Notre offre de services
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="100%">
          {/* Service 1 */}
          <Box 
            p={6} 
            bg="blue.50" 
            borderRadius="lg" 
            boxShadow="lg"
            textAlign="left"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Icon as={FcShop} boxSize={16} mb={4} />
            <Text fontSize="xl" fontWeight="bold" mb={2}>Abonnement mensuel</Text>
            <Text color="gray.600">
              À partir de 450€ par mois, notre offre d'abonnement vous donne accès à l'ensemble des fonctionnalités de ProtectoSystem. Aucun frais d'installation ni d'engagement sur la durée.
            </Text>
            <Text color="gray.600" mt={4}>
              Notre tarification est adaptée à la taille de votre entreprise et à vos besoins spécifiques.
            </Text>
          </Box>

          {/* Service 2 */}
          <Box 
            p={6} 
            bg="blue.50" 
            borderRadius="lg" 
            boxShadow="lg"
            textAlign="left"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Icon as={FcServices} boxSize={16} mb={4} />
            <Text fontSize="xl" fontWeight="bold" mb={2}>Services sur-mesure</Text>
            <Text color="gray.600">
              Au-delà de l'abonnement, nous proposons également des services d'intégration, de formation et de maintenance personnalisés. Notre équipe d'experts vous accompagne pour une mise en place fluide et une utilisation optimale de notre solution.
            </Text>
            <Text color="gray.600" mt={4}>
              Nos services sur-mesure sont facturés à la prestation, avec des devis adaptés à chaque projet. Nous travaillons en étroite collaboration avec vous pour vous proposer la meilleure offre.
            </Text>
          </Box>
        </SimpleGrid>
        <Text fontSize="lg" color="gray.600">
          Quel que soit votre budget, <Text as="span" color="blue.500">PROTECTOSYSTEM</Text> s'adapte à vos besoins et vous permet de bénéficier des dernières technologies pour améliorer l'efficacité de vos opérations de sécurité et de secours.
        </Text>
      </VStack>
    </Box>
  );
};

export default ServiceOfferSection;
