import React from 'react';
import { Box, SimpleGrid, Text, VStack, HStack, Icon } from '@chakra-ui/react';
import { FcPlanner, FcCollaboration, FcInspection, FcDepartment, FcManager, FcDocument } from 'react-icons/fc';

const KeyFeaturesSection: React.FC = () => {
  return (
    <Box py={10} px={{ base: 4, md: 20, lg: 40 }} bg="white">
      <VStack spacing={8} textAlign="center">
        <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
          Fonctionnalités clés
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="100%">
          {/* Feature 1 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcPlanner} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Cartographie en temps réel</Text>
              <Text color="gray.600">
                Visualisation instantanée de la position des équipes et des ressources sur des cartes interactives, permettant une coordination optimale des interventions.
              </Text>
            </VStack>
          </HStack>
          {/* Feature 2 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcInspection} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Suivi des interventions</Text>
              <Text color="gray.600">
                Enregistrement et suivi en temps réel des interventions, permettant une traçabilité complète des opérations de sécurité et de secours.
              </Text>
            </VStack>
          </HStack>
          {/* Feature 3 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcCollaboration} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Communication intégrée</Text>
              <Text color="gray.600">
                Système de messagerie sécurisée et de partage de fichiers en temps réel, facilitant la coordination entre les équipes sur le terrain et le centre de commandement.
              </Text>
            </VStack>
          </HStack>
          {/* Feature 4 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcManager} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Gestion des équipes</Text>
              <Text color="gray.600">
                Planification et affectation optimisées des équipes et des ressources, en fonction des besoins et des compétences de chacun. Suivi en temps réel de l'état et de l'emplacement du matériel nécessaire aux interventions.
              </Text>
            </VStack>
          </HStack>
          {/* Feature 5 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcDepartment} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Analyse prédictive</Text>
              <Text color="gray.600">
                Utilisation d'algorithmes d'IA pour anticiper les besoins en ressources et optimiser la planification des interventions basée sur l'historique des données.
              </Text>
            </VStack>
          </HStack>
          {/* Feature 6 */}
          <HStack align="start" spacing={4}>
            <Icon as={FcDocument} boxSize={12} />
            <VStack align="start">
              <Text fontSize="xl" fontWeight="bold">Rapports automatisés</Text>
              <Text color="gray.600">
                Génération automatique de rapports détaillés post-intervention, simplifiant les tâches administratives et améliorant l'analyse des opérations.
              </Text>
            </VStack>
          </HStack>
        </SimpleGrid>
        <Text fontSize="lg" color="gray.600">
          Ces fonctionnalités et plus encore, combinées à une interface utilisateur intuitive et personnalisable, font de ProtectoSystem une solution complète et puissante pour la gestion des missions de sécurité et de secours.
        </Text>
      </VStack>
    </Box>
  );
};

export default KeyFeaturesSection;
