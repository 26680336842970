import React from 'react';
import { Box, VStack, HStack, Text, Badge, Link, Icon } from '@chakra-ui/react';
import { FaLinkedin } from 'react-icons/fa';

const TeamSection: React.FC = () => {
  return (
    <Box
      textAlign="left"
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 6 }}
      bg="gray.50"
      borderRadius="md"
    >
      <VStack spacing={{ base: 4, md: 6 }} align="left" maxW="4xl" mx="auto">
        <Text
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
          textAlign="center"
        >
          Équipe dirigeante
        </Text>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" textAlign="center">
          ProtectoSystem s'appuie sur une équipe dirigeante expérimentée et passionnée, combinant expertise technique et connaissance approfondie du secteur de la sécurité et du secours :
        </Text>

        {/* Membre 1 */}
        <HStack spacing={4} align="start" bg="white" p={4} borderRadius="md" boxShadow="md">
          <Badge colorScheme="blue" fontSize="lg" borderRadius="full" p={2}>
            1
          </Badge>
          <Box>
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              <Link href="https://www.linkedin.com/in/vianney-r-630a1a204/?originalSubdomain=fr" isExternal color="blue.500">
                Vianney R. - Gérant <Icon as={FaLinkedin} boxSize={5} ml={2} />
              </Link>
            </Text>
            <Text fontSize="md" color="gray.600" mt={2}>
              Vianney a travaillé dans le monde de la sécurité de nuit et dirige aujourd'hui différents services de sécurité d'associations. Son expérience terrain est un atout majeur pour comprendre les besoins de nos clients.
            </Text>
          </Box>
        </HStack>

        {/* Membre 2 */}
        <HStack spacing={4} align="start" bg="white" p={4} borderRadius="md" boxShadow="md">
          <Badge colorScheme="green" fontSize="lg" borderRadius="full" p={2}>
            2
          </Badge>
          <Box>
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              <Link href="https://www.linkedin.com" isExternal color="blue.500">
                Guillaume R. - Développeur <Icon as={FaLinkedin} boxSize={5} ml={2} />
              </Link>
            </Text>
            <Text fontSize="md" color="gray.600" mt={2}>
              Guillaume est le développeur en charge de trouver les solutions techniques innovantes qui répondent aux problématiques de nos clients dans le domaine de la sécurité.
            </Text>
          </Box>
        </HStack>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" lineHeight="tall" textAlign="center" mt={6}>
          Cette équipe complémentaire possède l'expérience, la vision et la détermination nécessaires pour mener ProtectoSystem vers le succès et établir l'entreprise comme le leader incontesté du marché de la gestion des missions de sécurité et de secours.
        </Text>
      </VStack>
    </Box>
  );
};

export default TeamSection;
