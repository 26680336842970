import React from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Icon,
  Badge,
} from '@chakra-ui/react';
import { FcStart, FcLineChart, FcGlobe } from 'react-icons/fc';

const ObjectivesSection: React.FC = () => {
  return (
    <Box
      textAlign="left"
      py={{ base: 6, md: 10 }}
      px={{ base: 4, md: 6 }}
      bg="gray.50"
      backgroundImage="url('/path-to-your-background-image.png')" // Replace with your actual image path
      backgroundSize="cover"
      backgroundPosition="center"
      borderRadius="md"
    >
      <VStack spacing={{ base: 4, md: 6 }} align="left" maxW="4xl" mx="auto">
        <Text
          fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
          fontWeight="bold"
          color="gray.800"
          textAlign="center"
        >
          Nos objectifs
        </Text>

        {/* Objectif Court Terme */}
        <HStack spacing={4} align="start">
          <Badge colorScheme="blue" fontSize="lg" borderRadius="full" p={2}>
            1
          </Badge>
          <Box>
            <HStack>
              <Icon as={FcStart} w={6} h={6} />
              <Text fontSize="xl" fontWeight="bold" color="gray.800">
                Court terme (1-2 ans)
              </Text>
            </HStack>
            <Text fontSize="md" color="gray.600" mt={2}>
              Lancer notre plateforme sur le marché français et acquérir une base de clients significative parmi les services de sécurité et de secours locaux. Nous améliorer sans cesse avec les RETEX (retour d'expérience) de nos clients et partenaires.
            </Text>
          </Box>
        </HStack>

        {/* Objectif Moyen Terme */}
        <HStack spacing={4} align="start">
          <Badge colorScheme="green" fontSize="lg" borderRadius="full" p={2}>
            2
          </Badge>
          <Box>
            <HStack>
              <Icon as={FcLineChart} w={6} h={6} />
              <Text fontSize="xl" fontWeight="bold" color="gray.800">
                Moyen terme (3-5 ans)
              </Text>
            </HStack>
            <Text fontSize="md" color="gray.600" mt={2}>
              Étendre notre présence à l'échelle nationale, devenir un essentiel du marché français. Développer de nouvelles fonctionnalités basées sur l'intelligence artificielle et le big data ou encore les technologies OSINT.
            </Text>
          </Box>
        </HStack>

        {/* Objectif Long Terme */}
        <HStack spacing={4} align="start">
          <Badge colorScheme="orange" fontSize="lg" borderRadius="full" p={2}>
            3
          </Badge>
          <Box>
            <HStack>
              <Icon as={FcGlobe} w={6} h={6} />
              <Text fontSize="xl" fontWeight="bold" color="gray.800">
                Long terme (5-10 ans)
              </Text>
            </HStack>
            <Text fontSize="md" color="gray.600" mt={2}>
              Expansion internationale, en commençant par l'Europe. Diversifier notre offre pour inclure des solutions pour d'autres secteurs connexes (gestion des catastrophes, sécurité civile).
            </Text>
          </Box>
        </HStack>

        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.700" lineHeight="tall" textAlign="center" mt={6}>
          Ces objectifs ambitieux mais réalistes guideront notre croissance et notre développement, assurant une expansion durable et une amélioration continue de notre solution.
        </Text>
      </VStack>
    </Box>
  );
};

export default ObjectivesSection;
