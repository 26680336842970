import React from 'react';
import { Box, SimpleGrid, Text, VStack, Image, Grid, GridItem } from '@chakra-ui/react';

const SolutionSection: React.FC = () => {
  return (
    <Box py={10} px={{ base: 4, md: 20, lg: 40 }} bg="white">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        {/* Image section */}
        <Box textAlign="center"> {/* Centering the image */}
          <Image 
            src="/logoprotecto.png" 
            alt="Protecto Logo" 
            borderRadius="50%" 
            boxSize="200px" // Adjust the size as needed
            boxShadow="lg" 
            mx="auto" // Center the image horizontally
          />
        </Box>

        {/* Text section */}
        <VStack align="start" spacing={4}>
          <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
            Notre solution logicielle
          </Text>
          <Text fontSize="lg" color="gray.600">
            ProtectoSystem propose une plateforme logicielle intégrée, conçue spécifiquement pour répondre aux besoins complexes des organisations de sécurité et de secours. Notre solution se distingue par sa capacité à centraliser l'ensemble des aspects de la gestion des missions sur une interface unique et intuitive.
          </Text>
          <Text fontSize="lg" color="gray.600">
            La plateforme combine des fonctionnalités de géolocalisation, de gestion de données en temps réel, de communication sécurisée, de gestion d'entreprise, et de moyens de preuves. Elle s'adapte facilement aux protocoles existants de chaque organisation, assurant une intégration fluide dans les systèmes déjà en place.
          </Text>
          <Text fontSize="lg" color="gray.600">
            L'architecture modulaire de notre solution permet une personnalisation poussée, répondant ainsi aux besoins spécifiques de chaque client.
          </Text>
          <Text fontSize="lg" color="gray.600">
            La solution se compose d'un site web avec un cloud pour le poste de commandement, ainsi que d'applications mobiles iOS et Android dédiées aux opérateurs sur le terrain.
          </Text>
        </VStack>
      </SimpleGrid>

      {/* Features presentation */}
      <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6} mt={10}>
        <GridItem>
          <Box
            overflow="hidden"
            borderRadius="md"
            boxShadow="md"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Image 
              src="/image1.jpeg" 
              alt="Feature 1" 
              width="100%" 
              height="100%"
              objectFit="cover"
            />
          </Box>
        </GridItem>
        <GridItem>
          <Box
            overflow="hidden"
            borderRadius="md"
            boxShadow="md"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Image 
              src="/image2.jpeg" 
              alt="Feature 2" 
              width="100%" 
              height="100%"
              objectFit="cover"
            />
          </Box>
        </GridItem>
        <GridItem>
          <Box
            overflow="hidden"
            borderRadius="md"
            boxShadow="md"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Image 
              src="/image3.jpeg" 
              alt="Feature 3" 
              width="100%" 
              height="100%"
              objectFit="cover"
            />
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SolutionSection;
