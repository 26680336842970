import React from 'react';
import { Box, SimpleGrid, Text, VStack, Badge, Icon } from '@chakra-ui/react';
import { FcComboChart, FcCurrencyExchange, FcIdea } from 'react-icons/fc';

const ClientBenefitsSection: React.FC = () => {
  return (
    <Box 
      py={10} 
      px={{ base: 4, md: 20, lg: 40, xl: 60 }}  
      bg="white"
    >
      <VStack spacing={8} textAlign="center">
        <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
          Avantages pour nos clients
        </Text>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} width="100%">
          {/* Benefit 1 */}
          <Box 
            p={6} 
            bg="blue.50" 
            borderRadius="lg" 
            boxShadow="lg"
            textAlign="left"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Icon as={FcComboChart} boxSize={16} mb={4} />
            <Text fontSize="xl" fontWeight="bold" mb={2}>Efficacité opérationnelle accrue</Text>
            <Badge colorScheme="green" mb={4}>OPTIMISÉ</Badge>
            <Text color="gray.600">
              Grâce à notre plateforme, les temps d'intervention sont significativement réduits. La coordination améliorée entre les équipes permet une allocation optimale des ressources, résultant en des opérations plus rapides et plus efficaces.
            </Text>
          </Box>

          {/* Benefit 2 */}
          <Box 
            p={6} 
            bg="pink.50" 
            borderRadius="lg" 
            boxShadow="lg"
            textAlign="left"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Icon as={FcCurrencyExchange} boxSize={16} mb={4} />
            <Text fontSize="xl" fontWeight="bold" mb={2}>Réduction des coûts</Text>
            <Badge colorScheme="yellow" mb={4}>ÉCONOMIQUE</Badge>
            <Text color="gray.600">
              L'optimisation des ressources et l'automatisation de certaines tâches administratives permettent une réduction substantielle des coûts opérationnels. Nos clients rapportent en moyenne une économie de 20% sur leurs budgets de fonctionnement.
            </Text>
          </Box>

          {/* Benefit 3 */}
          <Box 
            p={6} 
            bg="green.50" 
            borderRadius="lg" 
            boxShadow="lg"
            textAlign="left"
            transition="transform 0.4s ease-out"
            _hover={{ transform: "scale(1.05)" }}
          >
            <Icon as={FcIdea} boxSize={16} mb={4} />
            <Text fontSize="xl" fontWeight="bold" mb={2}>Innovation de l'anticipation et de la conduite</Text>
            <Badge colorScheme="blue" mb={4}>INNOVANT</Badge>
            <Text color="gray.600">
              La visibilité en temps réel et l'analyse prédictive contribuent à une meilleure anticipation des risques, améliorant ainsi la sécurité des biens et des personnes protégées.
            </Text>
          </Box>
        </SimpleGrid>
        <Text fontSize="lg" color="gray.600">
          En choisissant ProtectoSystem, nos clients bénéficient d'une solution qui non seulement optimise leurs opérations quotidiennes, mais qui évolue également avec leurs besoins, garantissant une amélioration continue de leurs services de sécurité.
        </Text>
      </VStack>
    </Box>
  );
};

export default ClientBenefitsSection;
