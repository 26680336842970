import React from 'react';
import { Box, SimpleGrid, Text, VStack, Badge, Flex, Icon } from '@chakra-ui/react';
import { FcAssistant, FcIdea, FcCollaboration, FcApproval } from "react-icons/fc";

const MissionSection: React.FC = () => {
  return (
    <Box py={6} px={{ base: 4, md: 20, lg: 40 }} bg="gray.50">
      <VStack spacing={4} mb={10} textAlign="center">
        <Text fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} fontWeight="bold" color="gray.800">
          Notre mission
        </Text>
        <Text fontSize="lg" color="gray.600" maxW="4xl">
          Chez PROTECTOSYSTEM, nous nous engageons à révolutionner la manière dont les organisations de sécurité et de secours gèrent leurs opérations. Notre mission est de leur fournir les outils technologiques nécessaires pour sauver des vies et protéger les biens de manière plus efficace que jamais.
        </Text>
      </VStack>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} alignItems="center" justifyContent="center">
        {/* Card 1 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
          textAlign="center"
        >
          <VStack align="center" spacing={4}>
            <Badge colorScheme="blue" px={2} py={1} rounded="md">
              Excellence opérationnelle
            </Badge>
            <Icon as={FcAssistant} boxSize={16} />
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              Excellence opérationnelle
            </Text>
            <Text color="gray.600">
              Fournir une solution logicielle de pointe pour optimiser la gestion des missions de sécurité et de secours, permettant une efficacité opérationnelle pour les opérateurs. Avant tout, vous servir.
            </Text>
          </VStack>
        </Box>

        {/* Card 2 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
          textAlign="center"
        >
          <VStack align="center" spacing={4}>
            <Badge colorScheme="yellow" px={2} py={1} rounded="md">
              Innovation continue
            </Badge>
            <Icon as={FcIdea} boxSize={16} />
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              Innovation continue
            </Text>
            <Text color="gray.600">
              Rester à la pointe de l'innovation technologique pour anticiper et répondre aux besoins évolutifs du secteur de la sécurité et du secours.
            </Text>
          </VStack>
        </Box>

        {/* Card 3 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
          textAlign="center"
        >
          <VStack align="center" spacing={4}>
            <Badge colorScheme="green" px={2} py={1} rounded="md">
              Impact sociétal
            </Badge>
            <Icon as={FcCollaboration} boxSize={16} />
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              Impact sociétal
            </Text>
            <Text color="gray.600">
              Contribuer à la sécurité et au bien-être de la société en améliorant la réactivité et l'efficacité des services d'urgence grâce à notre solution.
            </Text>
          </VStack>
        </Box>

        {/* Card 4 */}
        <Box
          bg="blue.50"
          p={6}
          rounded="lg"
          shadow="md"
          _hover={{ shadow: 'lg' }}
          textAlign="center"
        >
          <VStack align="center" spacing={4}>
            <Badge colorScheme="purple" px={2} py={1} rounded="md">
              Partenariat de confiance
            </Badge>
            <Icon as={FcApproval} boxSize={16} />
            <Text fontSize="xl" fontWeight="bold" color="gray.800">
              Partenariat de confiance
            </Text>
            <Text color="gray.600">
              Établir des relations durables avec nos clients en leur offrant un support constant et en étant à l'écoute de leurs besoins spécifiques.
            </Text>
          </VStack>
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default MissionSection;
